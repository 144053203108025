import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'

const StyledError = styled.div`
  display: block;
  padding-bottom: 8px;
  font-family: 'Source Sans Pro';
  color: ${({ theme }) => get(theme, 'lightOrange')};
  font-weight: 400;
  font-size: 13px;
`

const FormError = ({ error }) => {
  if (isEmpty(error)) {
    return null
  }

  return (
    <StyledError>
      {error}
    </StyledError>
  )
}

FormError.propTypes = {
  error: PropTypes.string
}

FormError.defaultProps = {
  error: ''
}

export default FormError
